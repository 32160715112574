import React, { Component } from 'react';
import butter from '../../utils/buttercms';
import LoadingSpinner from '../loading-spinner';

const SlugCache = {};

class ButterPageContent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			content: null
		};
	}

	componentWillMount() {
		this.mounted = true;

		const slug = this.props.slug || 'first-test';

		if(SlugCache[slug]) {
			this.setState({
				content: SlugCache[slug]
			});

			return;
		}

		butter.page.retrieve('*', slug).then((resp) => {
			if(!this.mounted)
				return;

			SlugCache[slug] = resp.data.data;

			this.setState({
				content: resp.data.data
			})
		});
	}

	componetWillUnmount() {
		this.mounted = false;
	}

	render() {
		if (this.state.content) {
			const content = this.state.content;

			const html = { __html: content.fields.page_content };

			return (
				<div dangerouslySetInnerHTML={html}/>
			);
		} else {
			return (
				<LoadingSpinner title="Loading page..."/>
			);
		}
	}
}

export default ButterPageContent;

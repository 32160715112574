import React from 'react';
import styles from './footer.module.scss';

export default props => (
	<div className={styles.footer}>
		
		<p>
			<a target='_blank' href='https://www.google.com/maps/place/Pleasant+Hill+Church/@40.3091081,-84.8245865,15z/data=!4m5!3m4!1s0x0:0x6895a28d7c3bcff5!8m2!3d40.3091081!4d-84.8245865'>
			Pleasant Hill Church, 9945 N 800 E, Union City, IN 47390, United States
			</a>
		</p>
		
		<p>765-964-3664</p>
		
		<p><a target='_blank'
			href='mailto:pastor@mypleasanthillchurch.org'>
				pastor@mypleasanthillchurch.org
		</a></p>

		{props.butter && (<>
			<p className={styles.butter}>
				<a href='https://buttercms.com' title='Some pages powered by ButterCMS'>
					Content powered by &nbsp;
					<img src='https://cdn.buttercms.com/RyJ7UhcVTCRqrCFXwgCo'/>
				</a>
			</p>
		</>)}

	</div>
);

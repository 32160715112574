import React from 'react';
import styles from './hero.module.scss';

export default props => (
	<div className={styles.heroWrap + " hero--wrap"}>
		<div className={styles.heroImage + " hero--image"}  data-has-image={props.image ? "true" : "false"}>
			{props.image && <props.image/>}
			<div className={styles.heroContent + " hero--content"}>
				{props.children}
			</div>
		</div>
	</div>
);

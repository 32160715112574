import React from 'react';
import Img from "gatsby-image";
import { StaticQuery, graphql } from 'gatsby'

export default function Component(props) {
	return (
		<StaticQuery
			query={graphql`
				query {
					file(relativePath: { regex: "/kids-puzzle.jpg/" }) {
						childImageSharp {
							fluid(maxWidth: 2048) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			`}
			render={data => (
				<Img fluid={data.file.childImageSharp.fluid}/>
			)}
		/>
	)
}
